import { Button, PopoverCanvas, Spinner } from '@optimization/ssi-common';
import { cellToLatLng } from 'h3-js';
import { SuggestedDepotMarker } from '../types';
import { useMemo } from 'react';
import { useReverseGeocodingQuery } from 'app/services/mapbox';

interface Props {
  suggestedDepotMarker: SuggestedDepotMarker;
  onHideMarkerPopover: () => void;
  onShowDepotForm: () => void;
}

const SuggestedDepotPopover = ({ suggestedDepotMarker, onHideMarkerPopover, onShowDepotForm }: Props) => {
  const [latitude, longitude] = useMemo(
    () => cellToLatLng(suggestedDepotMarker.suggestedDepotPosition.h3Cell),
    [suggestedDepotMarker],
  );

  const reverseGeocodingQuery = useReverseGeocodingQuery({ latitude, longitude });

  const place = useMemo(
    () => reverseGeocodingQuery.data?.features?.[0].properties.full_address,
    [reverseGeocodingQuery],
  );

  return (
    <PopoverCanvas
      show
      hidePopoverCanvas={onHideMarkerPopover}
      style={{
        top: `${suggestedDepotMarker.position.y}px`,
        left: `${suggestedDepotMarker.position.x}px`,
        width: '275px',
      }}
    >
      <div className="flex justify-between">
        <div className="tds-detail-06">Identified depot</div>
        <Button variant="secondary" size="xs" text="Add depot" onClick={onShowDepotForm} />
      </div>
      <div className="tds-headline-07 mt-spacing-16">
        {reverseGeocodingQuery.isLoading && <Spinner size="xs" />}
        {place && <div className="tds-headline-07">{place}</div>}
      </div>
      <div className="tds-detail-07 flex w-full justify-betweens mt-spacing-16">
        <div className="w-50-percent">Vehicles</div>
        <div className="w-50-percent">{suggestedDepotMarker.suggestedDepotPosition.vehicles.length}</div>
      </div>
    </PopoverCanvas>
  );
};

export default SuggestedDepotPopover;
