import { DEPOT_MARKER_ICON_SIZE } from '../';
import { getBoundingBox } from 'geolocation-utils';
import { Location } from './types';
import { useEffect } from 'react';
import { WebMercatorViewport } from 'deck.gl/typed';

interface Props {
  locations: Location[];
  wrapperRef: React.RefObject<HTMLDivElement>;
  isFullScreen: boolean;
  setInitialViewState: React.Dispatch<
    React.SetStateAction<{
      latitude: number;
      longitude: number;
      zoom: number;
      bearing: number;
      pitch: number;
    }>
  >;
}

const useLookAtLocations = ({ locations, wrapperRef, isFullScreen, setInitialViewState }: Props) => {
  useEffect(() => {
    if (locations.length && wrapperRef.current) {
      const boundingBox: any = getBoundingBox(locations, 0);

      const bounds: [[number, number], [number, number]] = [
        [boundingBox.topLeft.lng, boundingBox.topLeft.lat],
        [boundingBox.bottomRight.lng, boundingBox.bottomRight.lat],
      ];

      const { latitude, longitude, zoom } = new WebMercatorViewport({
        width: wrapperRef.current.offsetWidth,
        height: isFullScreen ? wrapperRef.current.offsetWidth * 0.43 : 256,
      }).fitBounds(bounds, { padding: DEPOT_MARKER_ICON_SIZE });

      setInitialViewState((prev) => ({
        ...prev,
        latitude,
        longitude,
        zoom,
      }));
    }
  }, [locations, isFullScreen, wrapperRef, setInitialViewState]);
};

export default useLookAtLocations;
