import { SuggestedDepotPosition } from './types';

const suggestedDepotPositions: SuggestedDepotPosition[] = [
  { h3Cell: '8a1f10956a87fff', vehicles: [1, 2, 3, 4, 5] },
  { h3Cell: '8a1969975a9ffff', vehicles: [1, 2, 3] },
  { h3Cell: '8a1f059911b7fff', vehicles: [1] },
  { h3Cell: '8a08861a09affff', vehicles: [1] },
  { h3Cell: '8a1f2326266ffff', vehicles: [1] },
  { h3Cell: '8a1f150d555ffff', vehicles: [1] },
  { h3Cell: '8a1f00d7234ffff', vehicles: [1] },
  { h3Cell: '8a1f00d72347fff', vehicles: [1] },
  { h3Cell: '8a1f0635ca67fff', vehicles: [1] },
  { h3Cell: '8a1f150d540ffff', vehicles: [1] },
  { h3Cell: '8a08b30420effff', vehicles: [1] },
  { h3Cell: '8a1f059956effff', vehicles: [1] },
  { h3Cell: '8a1f00d7236ffff', vehicles: [1] },
  { h3Cell: '8a1f0634900ffff', vehicles: [1] },
];

export default suggestedDepotPositions;
