const existingDepotPositions: string[] = [
  '8a08864e2377fff',
  '8a08864ecb07fff',
  '8a08861a098ffff',
  '8a08864534dffff',
  '8a08861a0837fff',
  '8a08864ecb27fff',
  '8a081221b267fff',
  '8a081328d657fff',
  '8a1f23262297fff',
  '8a1f05991187fff',
  '8a1f15485507fff',
  '8a0886458887fff',
  '8a08864edd1ffff',
];

export default existingDepotPositions;
