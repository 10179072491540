import existingDepotPositions from './existingDepotPositions';
import suggestedDepotPositions from './suggestedDepotPositions';
import { cellToLatLng } from 'h3-js';
import { Location } from './types';
import { useMemo } from 'react';

const useLocations = (): Location[] =>
  useMemo(() => {
    return [
      ...suggestedDepotPositions.map((suggestedDepotPosition) => {
        const [lat, lng] = cellToLatLng(suggestedDepotPosition.h3Cell);

        return {
          h3Cell: suggestedDepotPosition.h3Cell,
          lat,
          lng,
        };
      }),
      ...existingDepotPositions.map((existingDepotPosition) => {
        const [lat, lng] = cellToLatLng(existingDepotPosition);

        return {
          h3Cell: existingDepotPosition,
          lat,
          lng,
        };
      }),
    ];
  }, []);

export default useLocations;
