import { PickingInfo } from 'deck.gl/typed';
import { PopoverMenu } from '@optimization/ssi-common';
import { useMemo } from 'react';

interface Props {
  pickingInfo: PickingInfo;
  solutionId: string;
  onHideMarkerPopover: () => void;
  onShowDepotForm: () => void;
}

const AddNewDepotPopoverMenu = ({ pickingInfo, solutionId, onHideMarkerPopover, onShowDepotForm }: Props) => {
  const options = useMemo(
    () => [
      {
        name: 'Add new depot',
        onClick: onShowDepotForm,
      },
    ],
    [onShowDepotForm],
  );

  return (
    <PopoverMenu
      show
      options={options}
      hidePopoverMenu={onHideMarkerPopover}
      style={{
        top: `${pickingInfo.pixel?.[1]}px`,
        left: `${pickingInfo.pixel?.[0]}px`,
      }}
    />
  );
};

export default AddNewDepotPopoverMenu;
