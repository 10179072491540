import Actions from './Actions';
import classes from './style.module.scss';
import ErrorBoundary from 'common/components/ErrorBoundary';
import EvaluatedChassis from './EvaluatedChassis';
import HelmetTitle from 'common/components/HelmetTitle';
import PageNotFound from 'common/components/PageNotFound';
import SolutionHomePageHeader from './utils/SolutionHomePageHeader';
import useLoadingText from 'common/hooks/useLoadingText';
import useTabs from './utils/useTabs';
import { BackLink } from '@optimization/sa-common';
import { EVALUATED_CHASSIS_SUMMARY_ENABLED } from 'app/config';
import { InlineTabs, invariant, Loading } from '@optimization/ssi-common';
import { isError404 } from 'common/utils/helper';
import { useGetDepotsQuery, useGetSolutionQuery, useGetVehiclesQuery } from 'app/services/solution';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import { useParams } from 'react-router-dom';
import { useRef, useState } from 'react';

const SolutionHome = () => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const solutionHomeTabsRef = useRef<HTMLDivElement | null>(null);

  const solutionQuery = useGetSolutionQuery(solutionId);
  const vehiclesQuery = useGetVehiclesQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = useTabs();

  const isLoading = solutionQuery.isFetching || vehiclesQuery.isFetching || depotsQuery.isFetching;
  const isError = solutionQuery.isError || vehiclesQuery.isError || depotsQuery.isError;

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isFetching,
    isLoadingCandidates: vehiclesQuery.isFetching,
    isLoadingDepots: depotsQuery.isFetching,
  });

  useMakeElementsSticky({
    solutionHomeTabsRef: vehiclesQuery.isSuccess ? solutionHomeTabsRef : undefined,
  });

  if (isError404(solutionQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <div className="tds-container">
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <HelmetTitle solutionId={solutionId} items={['Home']} />
      {solutionQuery.isSuccess && (
        <div className="mb-spacing-48">
          <BackLink url="/" className="mb-spacing-64">
            All solutions
          </BackLink>
          <SolutionHomePageHeader solutionId={solutionId} solutionName={solutionQuery.data.Name}>
            {solutionQuery.data.transformed.solutionNamePresentation}
          </SolutionHomePageHeader>
        </div>
      )}
      {vehiclesQuery.isSuccess && EVALUATED_CHASSIS_SUMMARY_ENABLED && (
        <div className={classes['solution-home-tabs']} ref={solutionHomeTabsRef}>
          <InlineTabs tabs={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </div>
      )}
      {tabs[tabIndex].name === 'Actions' && (
        <ErrorBoundary>
          <Actions solutionId={solutionId} />
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Evaluated chassis' && (
        <ErrorBoundary>
          <EvaluatedChassis solutionId={solutionId} />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default SolutionHome;
